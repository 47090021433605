<template>
  <v-dialog
      v-model="this.statusForm"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Lisa staatus</span>
      </v-card-title>
      <v-card-text>
        <form>
          <v-text-field
              v-model="status"
              label="Staatus"
              required
          ></v-text-field>
          <v-text-field
              v-model="additionalInfo"
              label="Lisainfo"
          ></v-text-field>
              <v-checkbox
                  v-model="internal"
                  label="Ära kuva kliendile (jälgimislehel)"
              ></v-checkbox>

        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeForm"
        >
          Katkesta
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="addStatus"
        >
          Lisa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StatusForm",
  props: ['statusForm'],
  data: () => ({
    status: '',
    additionalInfo: '',
    internal: false
  }),
  methods: {
    closeForm: function () {
      this.$emit('close_status_form');
    },
    addStatus: function () {
      let status = {
        status: this.status,
        additional_info: this.additionalInfo,
        internal: this.internal
      };
      this.$emit('add_status', status);
      this.$emit('close_status_form');
    }
  }
}
</script>