<template>
  <v-dialog v-model="packedStatusDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Märgi saadetised pakituks?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePackDialog">Ei</v-btn>
        <v-btn color="blue darken-1" text @click="shipmentsPacked">Jah</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PackedDialog",
  props: ['packedStatusDialog'],
  methods: {
    closePackDialog: function () {
      this.$emit("close_pack_dialog");
    },
    shipmentsPacked: function () {
      this.$emit("close_pack_dialog");
      this.$emit("add_packed_statuses");
    }
  }
}
</script>