<template>
  <v-dialog v-model="this.deleteDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Kas oled kindel, et soovid saadetise(d) kustutada?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Ei</v-btn>
        <v-btn color="blue darken-1" text @click="deleteShipment">Jah</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: ['deleteDialog'],
  methods: {
    closeDialog: function () {
      this.$emit("close_delete_dialog");
    },
    deleteShipment: function () {
      this.$emit("delete_shipment");
    }
  }
}
</script>