<template>
  <v-row justify="center">
    <v-dialog
        v-model="packingListDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card
      class="packing-list"
      >
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Alusta pakkimist</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="printLabels"
            >
              Prindi pakikaardid
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
            three-line
            subheader
        >
          <v-list-item
              v-for="shipment in selected"
              :key="shipment.id"
          >

            <h2>Tellimuse viide: {{ shipment.data.reference }}</h2>
            <PackingListProducts
                :items="shipment.data.order.products"
            ></PackingListProducts>
          </v-list-item>

        </v-list>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Order from "./Order";
import PackingListProducts from "./PackingListProducts";
export default {
  name: "PackingList",
  components: {PackingListProducts, Order},
  props: ['packingListDialog', 'selected'],
  data () {
    return {
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    closeDialog: function () {
      this.$emit("close_packing_list")
    },
    printLabels: function () {
      this.$emit("print_labels")
      this.$emit("close_packing_list")
    }
  }
}
</script>

<style scoped>

</style>