<template>
  <v-dialog v-model="this.shipment.packDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Märgi saadetis pakituks?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePackDialog">Ei</v-btn>
        <v-btn color="blue darken-1" text @click="shipmentPacked">Jah</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EmailDialog",
  props: ['dialog', 'shipment'],
  methods: {
    closePackDialog: function () {
      this.$emit("close_pack_dialog", this.shipment);
    },
    shipmentPacked: function () {
      this.$emit("close_pack_dialog", this.shipment);
      this.$emit("add_shipment_packed_status", this.shipment);
    }
  }
}
</script>