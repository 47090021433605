<template>
  <v-data-table
      flat
      :headers="headers"
      :items="items"
      sort-by="calories"
      class="order-items"
      :hide-default-footer=true
      :items-per-page=-1
  >
    <template v-slot:item.image_url="{ item }">
      <img  @error="onImageLoadFailure($event)"  v-bind:src="item.image_url" contain width="35"/>
    </template>
    <template v-slot:item.reference="{ item }">
        <span @click="copyText(item.reference)">
          {{ item.reference}}
        </span>
    </template>
    <template v-slot:item.price="{ item }">
        <span @click="copyText(parseFloat(item.price).toFixed(2))">
          {{ item.price | toCurrency}}
        </span>
    </template>
    <template v-slot:item.price_tax_excl="{ item }">
        <span @click="copyText(parseFloat(item.price_tax_excl).toFixed(2))">
          {{ item.price_tax_excl | toCurrency}}
        </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Order",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'image_url',
      },
      {
        text: 'Toode',
        value: 'name'
      },
      {
        text: 'Tootekood',
        value: 'reference' },
      {
        text: 'Kogus',
        value: 'quantity' },
      {
        text: 'Hind KM-ta',
        value: 'price_tax_excl'
      },
      {
        text: 'Hind KM-ga',
        value: 'price'
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ],
  }),
  props: ['items'],
  methods: {
    onImageLoadFailure (event) {
      event.target.src = '/assets/images/placeholder.png'
    },
    copyText(text) {
      this.$emit("copy_text_clipboard", text)
    },
  }
}
</script>

<style scoped>

</style>