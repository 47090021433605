<template>
  <v-card
      flat
      class="activities"
  >
    <v-card-title>
      <span class="text-h6">Saadetise teekond</span>
    </v-card-title>


    <v-card-text>
      <v-btn
          tile
          dark
          color="#213254"
          depressed
          @click="openTrackingPage"
      >
        Ava tracking leht
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <p v-if="this.trackingCode" class="carrier-tracking-code"><strong>Jälgimiskood:</strong> {{ this.trackingCode }}</p>
      <p v-else><v-btn
          tile
          dark
          color="#48a95a"
          depressed
          @click="addTrackingCode"
          class="mt-3 mb-3"
      >
        Lisa jälgimiskood
      </v-btn></p>
      <div class="activities-log">
      <div class="timeline-container">
      <v-timeline
          align-top
          dense
      >
          <v-timeline-item
              v-for="(event) in this.events"
              :key="event.state"
              small
              v-bind:class="event.state"
          >
            <div>
              <div class="font-weight-normal">
                {{ event.date }}
                <br>
                <v-chip
                    class="white--text ml-0 app-event"
                    :color="getEventOrigin(event.state).color"
                    label
                    small
                >
                  {{ getEventOrigin(event.state).name }}
                </v-chip>
                <strong>{{ getEventTranslation(event.state) }}</strong>
              </div>
              <div>{{ event.additional_info }}</div>
            </div>
          </v-timeline-item>
      </v-timeline>
      </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Activitys",
  props: ['events', 'destination', 'shipmentID', 'trackingCode'],
  data: () => ({
    timeout: 1500,
    eventStatus: ''
  }),
  methods: {
    openTrackingPage: function ()
    {
      window.open('https://tracking.connector.ee/et/' + this.shipmentID, '_blank');
    },
    getEventTranslation: function(event) {
      if (event === 'delivered') {
        return 'Kliendile üle antud'
      }
      if (event === 'destination_in') {
        return 'Jõudis pakiautomaati'
      }
      if (event === 'terminal') {
        return 'Saadetis on  sorteerimiskeskuses'
      }
      if (event === 'registered') {
        return 'Info vastu võetud'
      }
      if (event === 'pudo_in') {
        return 'Saadetis saabus postiasutusse'
      }
      if (event === 'in_transit') {
        return 'Saadetis on teel sihtkohta'
      }
      if (event === 'handed_over_to_courier') {
        return 'Kullerile üle antud'
      }
      if (event === 'packed') {
        return 'Pakitud'
      }
      if (event === 'return_terminal') {
        return 'Tagastus: Sorteerimiskeskuses'
      }
      if (event === 'return_out') {
        return 'Tagastus: Saadetis läks tagastusse'
      }
      if (event === 'returned') {
        return 'Tagastatud'
      }
      if (event === 'tracking_email') {
        return 'Email: jälgmilehe link saadetud'
      }
      return event;
    },
    getEventOrigin: function (event) {
      if (event === 'delivered' ||
          event === 'destination_in' ||
          event === 'terminal' ||
          event === 'pudo_in' ||
          event === 'in_transit' ||
          event === 'handed_over_to_courier' ||
          event === 'return_out' ||
          event === 'returned'
      ) {
        return {
          name: 'Kuller',
          color: '#ffb001'
        }
      } else if (event === 'tracking_email') {
        return {
          name: 'APP',
          color: '#272727'
        }
      } else if (event === 'registered') {
        return {
          name: 'Pood',
          color: '#ffb001'
        }
      } else {
        return {
          name: 'Kasutaja',
          color: '#48a95a'
        }
      }
    },
    addStatus: function () {
      console.log(this.eventStatus);
    }
  }
}
</script>