var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"packing-list-item",attrs:{"flat":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","hide-default-footer":true,"items-per-page":-1,"hide-default-header":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image_url,"contain":"","width":"35"},on:{"error":function($event){return _vm.onImageLoadFailure($event)}}})]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.copyText(item.reference)}}},[_vm._v(" "+_vm._s(item.reference)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){_vm.copyText(parseFloat(item.price).toFixed(2))}}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.price))+" ")])]}},{key:"item.price_tax_excl",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){_vm.copyText(parseFloat(item.price_tax_excl).toFixed(2))}}},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.price_tax_excl))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }