<template>
    <v-dialog
        v-model="this.smsForm"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Saada kliendile SMS sõnum</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
              outlined
              name="input-7-4"
              label="Sõnumi sisu"
              v-model="message"
              hide-details
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
          >
            Katkesta
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="sendSMS"
          >
            Saada
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "SMSForm",
  props: ['smsForm'],
  data: () => ({
    message: ''
  }),
  methods: {
    closeDialog: function () {
      this.$emit("close_sms_form")
    },
    sendSMS: function () {
      this.$emit("send_sms", this.message)
      this.$emit("close_sms_form")
    }
  }
}
</script>