<template>
  <div>
  <h1>Saadetised</h1>
    <v-btn
        dark
        absolute
        top
        right
    >
      Uus saadetis
    </v-btn>
  <v-data-table
      v-model="selected"
      :headers="headers"
      :hide-default-footer=true
      :items="shipments"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="shipments-table"
      :loading="loading"
      :items-per-page=20
      loading-text="Laeb... palun oota"
      @click:row="rowClick"
      show-select
  >
    <template v-slot:item.status="{ item }">
      <div :class="item.status">
        <v-icon v-if="item.status === 'packed'">mdi-package-variant-closed</v-icon>
        <v-icon v-if="item.status === 'registered'">mdi-package-variant</v-icon>
        <v-icon v-if="item.status === 'delivered'">mdi-check-circle</v-icon>
        <v-icon v-if="item.status === 'delayed'">mdi-flag</v-icon>
      </div>
    </template>
    <template v-slot:item.carrier="{ item }">
      <span v-if="item.carrier == 'CollectionPoint'"><strong>Tulen ise järgi</strong></span>
      <img v-if="item.carrier !== 'CollectionPoint'" v-bind:src="'/assets/images/carriers/' + item.carrier + '.png' " contain width="54"/>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <v-expand-transition>
      <td :colspan="headers.length">
        <Order
            :items="item.data.order.products"
            v-on:copy_text_clipboard="copyText"
        ></Order>
        <v-row class="order-info shipment-info"  justify="space-between">
          <v-col class="col-sm-12 col-md-6 col-12">
            <p><strong>Tellimuse viide:</strong> <span @click="copyText(item.data.reference)">{{ item.data.reference }}</span></p>
            <p v-if="item.destination.name">
              <strong>Sihtpunkt:</strong>  <span @click="copyText(item.destination.name)">{{ item.destination.name }}</span>
              <v-icon
                  small
                  class="ml-2"
                  @click="editShipmentDestination(item)"
              >
                mdi-pencil
              </v-icon>
            </p>
            <p v-else>
              <strong>Sihtpunkt:</strong> <span @click="copyText(item.data.destination.street + ', ' + item.data.destination.city + ', ' +  item.data.destination.zip_code)">{{ item.data.destination.street }}, {{ item.data.destination.city }}, {{ item.data.destination.zip_code }}</span>
              <v-icon
                  small
                  class="ml-2"
                  @click="editShipmentDestination(item)"
              >
                mdi-pencil
              </v-icon>
            </p>
          </v-col>
          <v-col class="col-sm-12 col-md-3 col-12">
            <div v-if="item.data.additional_services && item.data.additional_services.length !== 0">
              <strong>Lisateenused:</strong>
              <p v-for="(service, name) in item.data.additional_services">
                <strong>{{ name }}:</strong> {{ service.amount | toCurrency }}
              </p>
            </div>
          </v-col>
          <v-col class="col-sm-12 col-md-3 col-12">
            <p><strong>Tooted kokku:</strong> {{ item.data.order.product_total | toCurrency }}</p>
            <p v-if="item.data.order.discount > 0"><strong>Allhindlus:</strong> -{{ item.data.order.discount | toCurrency }}</p>
            <p><strong>Transport:</strong> {{ item.data.order.transport_total | toCurrency }}</p>
            <p><strong>Kokku:</strong> {{ item.data.order.total | toCurrency }}</p>
          </v-col>
        </v-row>
        <v-row class="order-info" justify="space-between">
          <v-col class="col-sm-12 col-md-4 col-12 mt-3">
            <Activitys
                :events="item.events"
                :destination="item.destination"
                :shipmentID="item.id"
                :trackingCode="item.tracking_code"
                :key="item.componentKey"
            ></Activitys>
          </v-col>
          <v-col class="col-sm-12 col-md-8 col-12">
            <CustomerInfo
                :data="item.data"
                :hasInvoiceAddress="item.hasInvoiceAddress"
                v-on:copy_text_clipboard="copyText"></CustomerInfo>
          </v-col>
        </v-row>
        <ShipmentActions
            :shipment="item"
            v-on:open_pack_dialog="openPackDialog"
            v-on:show_loading_overlay="showLoadingOverlay"
            v-on:close_loading_overlay="closeLoadingOverlay"
            v-on:add_status="addStatus"
            v-on:send_sms="sendSMS"
            v-on:delete_shipment="deleteShipment"
            v-on:shipment_delivered="shipmentDelivered"
            v-on:add_shipment_packed_status="addShipmentPackedStatus"
            v-on:duplicate_shipment="duplicateShipment"
        ></ShipmentActions>
      </td>
      </v-expand-transition>
      <EmailDialog
          :shipment="item"
          v-on:close_pack_dialog="closePackDialog"
          v-on:add_shipment_packed_status="addShipmentPackedStatus"
      ></EmailDialog>
    </template>
  </v-data-table>
    <div class="bulk-actions">
      <v-select
          :items="actions"
          v-model="selectedAction"
          label="Mass tegevus"
          background-color="#fff"
          color="#0d1822"
          :single-line=true
          @change="bulkAction"
          :disabled="!this.selected.length"
      ></v-select>
    </div>
  <Pagination :pagination="this.pagination"
              :page="this.page"
              :searchQuery="this.searchQuery"
              v-on:load_new_page="loadNewPage"></Pagination>
    <PackingList
        :packingListDialog="packingListDialog"
        :selected="selected"
        v-on:close_packing_list="closePackingList"
        v-on:print_labels="printLabels"
    >

    </PackingList>
    <DeleteDialog
        :deleteDialog="this.deleteDialog"
        v-on:close_delete_dialog="closeDeleteDialog"
        v-on:delete_shipment="deleteShipments"
    >
    </DeleteDialog>
    <PackedDialog
        :packedStatusDialog="this.packedStatusDialog"
        v-on:close_pack_dialog="closePackedStatusDialog"
        v-on:add_packed_statuses="addPackedStatuses"
    ></PackedDialog>
    <EditDestinationForm
    :editDestination="editDestination"
    :shipmentDestination="shipmentDestination"
    v-on:close_destination_dialog="closeDestinationDialog"
    v-on:fetch_shipment="fetchShipment"
    ></EditDestinationForm>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="#6aa775"
    >
      Kopeeritud
    </v-snackbar>
  </div>
</template>
<script>
    import Activitys from "../components/Activitys";
    import ShipmentActions from "../components/ShipmentActions";
    import Order from "../components/Order";
    import EmailDialog from "../components/EmailDialog";
    import Pagination from "../components/Pagination";
    import CustomerInfo from "../components/CustomerInfo";
    import printJS from "print-js";
    import PackingList from "../components/PackingList";
    import DeleteDialog from "../components/DeleteDialog";
    import PackedDialog from "../components/PackedDialog";
    import EditDestinationForm from "../components/EditDestinationForm";
    const axios = require('axios');
    export default {
      components: {
        EditDestinationForm,
        PackedDialog,
        DeleteDialog, PackingList, CustomerInfo, Pagination, EmailDialog, Order, ShipmentActions, Activitys},
      data () {
        return {
          selected: [],
          expanded: [],
          singleExpand: false,
          loading: true,
          headers: [
            { text: 'Staatus', align: 'start', value: 'status' },
            { text: 'Pood', value: 'shop' },
            { text: 'Kuller', value: 'carrier' },
            { text: 'Tellimuse viide', value: 'reference' },
            { text: 'Kliendi nimi', value: 'customer' },
            { text: 'Kuupäev', value: 'created' },
            { text: '', value: 'data-table-expand' },
          ],
          shipments: [],
          page: 1,
          pagination: 0,
          loadingOverlay: false,
          snackbar: false,
          timeout: 1500,
          searchQuery: false,
          statusQuery: false,
          actions: [
            {
              'text': 'Alusta pakkimist',
              'value': 'start-packing'
            },
            {
              'text': 'Prindi pakikaardid',
              'value': 'print-labels'
            },
            {
              'text': 'Märgi saadetised pakkituks',
              'value': 'packed'
            },
            {
              'text': 'Kustuta saadetised',
              'value': 'delete'
            }
          ],
          selectedAction: null,
          packingListDialog: false,
          deleteDialog: false,
          packedStatusDialog: false,
          shipmentDestination: {
            carrier: false,
            data: {
              service: 'PS',
              carrier: false,
              destination: {
                country: 'EE'
              }
            }
          },
          editDestination: false
        }
      },
      watch: {
        '$route.params.search': {
          handler: function() {
            this.searchQuery = this.$route.query.search;
            this.statusQuery = this.$route.query.status;
            this.fetchShipments();
          },
          deep: true,
          immediate: true
        },
      },
      methods: {
        fetchShipments: function () {
          this.loading = true;
          this.shipments = [];
          let self = this;
          let shipmentUrl = axios.defaults.baseURL + '/shipments';
          if (this.page > 1) {
            shipmentUrl = shipmentUrl + '/' + this.page;
          }

          if (this.statusQuery) {
            shipmentUrl = shipmentUrl + '?query=status:' + this.statusQuery;
          }

          if (this.searchQuery) {
            shipmentUrl = shipmentUrl + '?query=' + this.searchQuery;
          }

          axios.get(shipmentUrl).then((response) => {
            if (response.data.error) {
              this.shipments = [];
              this.loading = false;
              console.log(this.shipments);
            } else {
              for (let i = 0; i < response.data.shipments.length; i++) {
                let shipment = response.data.shipments[i];
                let hasInvoiceAddress = false;
                if (typeof shipment.data.invoice_address !== 'undefined') {
                  if (shipment.data.invoice_address.company_name) {
                    hasInvoiceAddress = true;
                  }
                }
                let item = shipment;
                item.status = this.getStatus(shipment);
                item.shop = shipment.data.order.shop_name;
                item.reference = shipment.data.reference;
                item.customer = this.getCustomerName(shipment.data, hasInvoiceAddress);
                item.dialog = false;
                item.packDialog = false;
                item.hasInvoiceAddress = hasInvoiceAddress
                this.shipments.push(item);
                this.pagination = response.data.pages;
                this.loading = false;
              }
            }
          }).catch(function (error) {
            if (error.response) {
              if (error.response.status === 401) {
                self.$router.push('/')
              }
            }
          });
        },
        fetchShipment: function (shipment_id) {
          axios.get(axios.defaults.baseURL + '/shipment/' + shipment_id)
              .then(response => {
                let shipment = response.data
                for (let shipment_key in this.shipments) {
                  if (this.shipments[shipment_key].id === shipment.id) {
                    this.shipments[shipment_key].data.carrier = shipment.data.carrier;
                    this.shipments[shipment_key].data.destination = shipment.data.destination;
                    this.shipments[shipment_key].destination = shipment.destination;
                    this.shipments[shipment_key].carrier = shipment.carrier;
                    console.log(this.shipments[shipment_key]);
                  }
                }
                console.log(this.shipments);
              })
        },
        getStatus: function(shipment) {
          if (shipment.delivered) {
            return 'delivered'
          }
          if (shipment.packed && !shipment.delayed) {
            return 'packed'
          }
          if (shipment.returned) {
            return 'returned'
          }
          if (shipment.delayed) {
            return 'delayed'
          }
          return 'registered';
        },
        getCustomerName: function (data, hasInvoiceAddress) {
          if (hasInvoiceAddress) {
            return data.invoice_address.company_name + ' - ' + data.recipient.name
          }

          return data.recipient.name;
        },
        openEditDialog: function (shipmentID) {
          for (let shipment_key in this.shipments) {
            if (this.shipments[shipment_key].id === shipmentID) {
              this.shipments[shipment_key].dialog = true;
            }
          }
        },
        closeEditDialog: function (shipmentID) {
          for (let shipment_key in this.shipments) {
            if (this.shipments[shipment_key].id === shipmentID) {
              this.shipments[shipment_key].dialog = false;
            }
          }
        },
        openPackDialog: function (shipment) {
          if (!shipment.packed) {
            shipment.packDialog = true;
          }
        },
        closePackDialog: function (shipment) {
          shipment.packDialog = false;
        },
        closePackedStatusDialog: function () {
          this.selectedAction = null;
          this.packedStatusDialog = false;
        },
        addShipmentPackedStatus: function (shipment) {
          let payload = {
            shipment_id: shipment.id,
            status: 'packed'
          }
          this.addStatus(payload)
        },
        addPackedStatuses: function () {
          this.showLoadingOverlay();
          for (let shipment_key in this.selected) {
            let shipment = this.selected[shipment_key];
            if (!shipment.packed) {
              let payload = {
                shipment_id: shipment.id,
                status: 'packed'
              }
              this.addStatus(payload)
            }
          }
          this.closeLoadingOverlay();
        },
        sendSMS: function (payload) {
          axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
              .then(response => {
                axios.post(axios.defaults.baseURL + '/shipments/sms',
                    payload)
                    .then(response => {
                      if (response.data.status === 'success') {
                        axios.get(axios.defaults.baseURL + '/shipment/' + payload.shipment_id)
                            .then(response => {
                              payload.additional_info = payload.message;
                              payload.internal = true;
                              payload.status = 'SMS';
                              this.addStatus(payload);
                            })
                      }
                    })
              })
        },
        addStatus: function (payload) {
          let self = this;
          axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
              .then(response => {
                axios.post(axios.defaults.baseURL + '/shipments/status',
                    payload)
                    .then(response => {
                          if (response.data.status === 'success') {
                            axios.get(axios.defaults.baseURL + '/shipment/' + payload.shipment_id)
                                .then(response => {
                                  let shipment = response.data
                                  for (let shipment_key in this.shipments) {
                                    if (this.shipments[shipment_key].id === shipment.id) {
                                      this.shipments[shipment_key].packed = 1;
                                      this.shipments[shipment_key].status = self.getStatus(shipment);
                                      this.shipments[shipment_key].events = shipment.events;
                                    }
                                  }
                                })
                          }
                        }
                    )
              })
        },
        loadNewPage: function (page) {
          this.page = page;
          this.fetchShipments();
        },
        rowClick: function (value) {
          if (this.expanded.includes(value)) {
            this.expanded = [];
          } else {
            this.expanded = [
              value
            ];
          }
        },
        showLoadingOverlay: function () {
          this.loadingOverlay = true;
        },
        closeLoadingOverlay: function () {
          this.selectedAction = null;
          this.loadingOverlay = false;
        },
        copyText(text) {
          let name = text;
          navigator.clipboard.writeText(name);
          this.snackbar = true;
        },
        deleteShipment: function (shipment) {
          axios.get(axios.defaults.baseURL + '/shipment/delete/' + shipment.id)
              .then(response => {
                if (response.data.status === 'success') {
                  this.shipments.splice(this.shipments.indexOf(shipment), 1);
                }
              })
        },
        deleteShipments: function () {
          this.deleteDialog = false;
          this.showLoadingOverlay();
          var seletedShipmentsIds = [];
          for (let shipment_key in this.selected) {
            let shipment = this.selected[shipment_key];
            seletedShipmentsIds.push(shipment.id)
          }
          axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
              .then(response => {
                axios.post(axios.defaults.baseURL + '/shipment/delete',
                    seletedShipmentsIds)
                    .then(response => {
                      this.closeLoadingOverlay();
                      this.fetchShipments();
                    })
              })
        },
        closeDeleteDialog: function () {
          this.selectedAction = null;
          this.deleteDialog = false;
        },
        duplicateShipment: function (shipment) {
          axios.get(axios.defaults.baseURL + '/shipment/duplicate/' + shipment.id)
              .then(response => {
                this.fetchShipments();
              })
        },
        shipmentDelivered: function (shipment) {
          let payload = {
            shipment_id: shipment.id,
            status: 'delivered'
          }
          this.addStatus(payload);
        },
        printLabels: function () {
          this.showLoadingOverlay();
          var seletedShipmentsIds = [];
          for (let shipment_key in this.selected) {
            let shipment = this.selected[shipment_key];
            seletedShipmentsIds.push(shipment.id)
          }

          axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
              .then(response => {
                axios.post(axios.defaults.baseURL + '/shipments/labels',
                    seletedShipmentsIds)
                    .then(response => {
                      printJS({
                        printable: response.data,
                        type: 'pdf',
                        base64: true,
                        onLoadingEnd: this.closeLoadingOverlay,
                        onPrintDialogClose: this.shipmentsPacked,
                      });
                    })
              });
        },
        bulkAction: function (actions) {
          if (this.selected.length) {
            if (actions === 'print-labels') {
              this.printLabels();
            }
            if (actions === 'delete') {
              this.deleteDialog = true;
            }
            if (actions === 'packed') {
              this.shipmentsPacked()
            }
            if (actions === 'start-packing') {
              this.packingListDialog = true;
            }
          }
        },
        shipmentsPacked: function () {
          this.packedStatusDialog = true;
        },
        closePackingList: function () {
          this.selectedAction = null;
          this.packingListDialog = false;
        },
        editShipmentDestination: function (shipment) {
          this.shipmentDestination = shipment;
          this.editDestination = true;
        },
        closeDestinationDialog: function () {
          this.editDestination = false;
        }
      }
    }
</script>
