<template>
    <div class="shipment-actions">
          <v-btn
              tile
              dark
              color="#48a95a"
              depressed
              @click="printPDF"
          >
            <v-icon left>
              mdi-printer
            </v-icon>
            Prindi pakikaart
          </v-btn>

        <v-btn
            v-if="!this.shipment.packed"
            tile
            dark
            color="#ef9c21"
            depressed
            @click="addShipmentPackedStatus"
        >
          <v-icon left>
            mdi-package-variant-closed
          </v-icon>
          Märgi saadetis pakituks
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="#213254"
                dark
                depressed
                v-bind="attrs"
                v-on="on"
                class="float-right"
            >
              Lisavalikud
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="action(item.action)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      <SMSForm
          :smsForm="this.smsForm"
          v-on:close_sms_form="closeSmsForm"
          v-on:send_sms="sendSMS"
      ></SMSForm>
      <StatusForm
          :statusForm="this.statusForm"
          v-on:add_status="addStatus"
          v-on:close_status_form="closeStatusForm"
      ></StatusForm>
      <DeleteDialog
          :deleteDialog="this.deleteDialog"
          v-on:close_delete_dialog="closeDeleteDialog"
          v-on:delete_shipment="deleteShipment"
      >
      </DeleteDialog>
    </div>
</template>

<script>
import axios from "axios";
import printJS from "print-js";
import SMSForm from "./SMSForm";
import StatusForm from "./StatusForm";
import DeleteDialog from "../components/DeleteDialog";

export default {
  name: "ShipmentActions",
  components: {DeleteDialog, StatusForm, SMSForm},
  props: ['shipment'],
  data: () => ({
    items: [
      {
        title: 'Saada jälgimiskoodi e-mail',
        icon: 'mdi-email',
        action: 'email'
      },
      {
        title: 'Lisa staatus',
        icon: 'mdi-math-log',
        action: 'status'
      },
      {
        title: 'Loo saadetisele lisa pakisilt',
        icon: 'mdi-file-document-multiple',
        action: 'duplicate'
      },
      {
        title: 'Saada SMS sõnum',
        icon: 'mdi-cellphone-message',
        action: 'sms'
      },
      {
        title: 'Saada jälgimiskoodi SMS',
        icon: 'mdi-radar',
        action: 'tracking-sms'
      },
      {
        title: 'Märgi saadetis kohale toimetatuks',
        icon: 'mdi-check-circle',
        action: 'delivered'
      },
      {
        title: 'Kustuta',
        icon: 'mdi-delete-forever',
        action: 'delete'
      },
    ],
    smsForm: false,
    statusForm: false,
    deleteDialog: false
  }),
  methods: {
    printPDF() {
      this.$emit("show_loading_overlay")
      axios.get(axios.defaults.baseURL + '/shipments/label/' + this.shipment.id).then((response) => {
        printJS({
          printable: response.data,
          type: 'pdf',
          base64: true,
          onPrintDialogClose: this.openPackModal,
          onLoadingEnd: this.closeLoadingOverlay
        });
      }).catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
    },
    addShipmentPackedStatus: function (){
      this.$emit("add_shipment_packed_status", this.shipment)
    },
    openPackModal: function (){
      this.$emit("open_pack_dialog", this.shipment)
    },
    closeLoadingOverlay: function () {
      this.$emit("close_loading_overlay")
    },
    closeSmsForm: function () {
      this.smsForm = false
    },
    closeStatusForm: function () {
      this.statusForm = false
    },
    addStatus: function (status) {
      status.shipment_id = this.shipment.id;
      this.$emit('add_status', status);
    },
    closeDeleteDialog: function () {
      this.deleteDialog = false;
    },
    deleteShipment: function () {
      this.deleteDialog = false;
      this.$emit('delete_shipment', this.shipment);
    },
    sendSMS: function (message) {
      let payload = {
        message: message,
        shipment_id: this.shipment.id
      }
      this.$emit('send_sms', payload);
    },
    action: function (action) {
      if (action === 'sms') {
        this.smsForm = true
      }
      if (action === 'status') {
        this.statusForm = true
      }
      if (action === 'delete') {
        this.deleteDialog = true
      }
      if (action === 'delivered') {
        this.$emit('shipment_delivered', this.shipment);
      }
      if (action === 'duplicate') {
        this.$emit('duplicate_shipment', this.shipment);
      }
    }
  }
}
</script>

<style scoped>

</style>