<template>
  <div class="customer-info">
    <v-row justify="space-between">
      <v-col v-if="!this.hasInvoiceAddress" class="col-sm-12 col-md-6 col-12">
      </v-col>
      <v-col v-if="this.hasInvoiceAddress" class="col-sm-12 col-md-6 col-12">
        <v-card
            flat
        >
          <v-card-title>
            <span class="text-h6">Arve andmed</span>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item @click="copyText(data.invoice_address.name)">
                <strong>Nimi: </strong> {{ this.data.invoice_address.name }}
              </v-list-item>
              <v-list-item @click="copyText(data.recipient.email)">
                <strong>Email: </strong>
                {{ this.data.recipient.email }}
              </v-list-item>
              <v-list-item @click="copyText(data.invoice_address.phone)">
                <strong>Telefon: </strong>
                {{ this.data.invoice_address.phone }}
              </v-list-item>
              <v-list-item @click="copyText(data.invoice_address.company_name)">
                <strong>Ettevõte: </strong>
                {{ this.data.invoice_address.company_name }}
              </v-list-item>

              <v-list-item @click="copyText(data.invoice_address.company_reg_code)">
                <strong>Reg. nr: </strong>
                {{ this.data.invoice_address.company_reg_code }}
              </v-list-item>
              <v-list-item @click="copyText(data.invoice_address.address)">
                <strong>Aadress: </strong>
                {{ this.data.invoice_address.address }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="float-left col-sm-12 col-md-6 col-12">
        <v-card
            flat
        >
          <v-card-title>
            <span class="text-h6">Kliendi andmed</span>
            <v-icon
                small
                class="mr-2 float-right"
                @click="editRecipient"
            >
              mdi-pencil
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item @click="copyText(data.recipient.name)">
                <strong>Nimi: </strong>
                {{ this.data.recipient.name }}
              </v-list-item>
              <v-list-item @click="copyText(data.recipient.phone)">
                <strong>Telefon: </strong>
                {{ this.data.recipient.phone }}
              </v-list-item>
              <v-list-item @click="copyText(data.recipient.email)">
                <strong>Email: </strong>
                {{ this.data.recipient.email }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CustomerInfo",
  props: ['data', 'hasInvoiceAddress'],
  data: () => ({
  }),
  methods: {
    copyText(text) {
      this.$emit("copy_text_clipboard", text)
    },
    editRecipient: function () {
      console.log('edit');
    }
  }
}
</script>

<style scoped>

</style>