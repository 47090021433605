<template>
  <v-dialog
      v-model="this.editDestination"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Muuda sihtpunkti</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-select
                  label="Kuller"
                  :items="couriers"
                  v-model="shipmentDestination.data.carrier"
                  @change="changeCourier"
              >
              </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-radio-group
                  v-model="shipmentDestination.data.service"
                  row
              >
                <v-radio
                    label="Automaat"
                    value="PS"
                ></v-radio>
                <v-radio
                    label="Kuller"
                    value="COURIER"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="shipmentDestination.data.service === 'PS'">
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-select
                  label="Riik"
                  :items="countries"
                  v-model="shipmentDestination.data.destination.country"
                  @change="changeCountry"
              >
              </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-autocomplete
                  label="Asukoht"
                  :items="terminals"
                  v-model="shipmentDestination.data.destination.id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-if="shipmentDestination.data.service === 'COURIER'">
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-select
                  label="Riik"
                  :items="countries"
                  v-model="shipmentDestination.data.destination.country"
                  @change="changeCountry"
              >
              </v-select>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-text-field
                  label="Tänav"
                  v-model="shipmentDestination.data.destination.street"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-text-field
                  label="Linn"
                  v-model="shipmentDestination.data.destination.city"
              >
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                md="6"
            >
              <v-text-field
                  label="Postikood"
                  v-model="shipmentDestination.data.destination.zip_code"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
        >
          Katkesta
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="updateDestination"
        >
          Salvesta
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import printJS from "print-js";

export default {
  name: "EditDestinationForm",
  props: ['editDestination', 'shipmentDestination'],
  data: () => ({
      couriers: [
        {
          'text': 'SmartPOST',
          'value': 'smartpost'
        },
        {
          'text': 'Omniva',
          'value': 'omniva'
        },
        {
          'text': 'DPD',
          'value': 'dpd'
        },
        {
          'text': 'Venipak',
          'value': 'venipak'
        },
        {
          'text': 'Tulen ise järgi',
          'value': 'collection-points'
        }
      ],
      countries: [
        {
          'text': 'Eesti',
          'value': 'EE'
        },
        {
          'text': 'Soome',
          'value': 'FI'
        },
        {
          'text': 'Läti',
          'value': 'LV'
        },
        {
          'text': 'Leedu',
          'value': 'LT'
        }
      ],
      terminals: [],
    }
  ),
  watch: {
    shipmentDestination: function (newVal, oldVal) {
      if (newVal.data.service === 'PS' && newVal.data.carrier !== oldVal.data.carrier) {
        this.shipmentDestination.data.carrier = newVal.data.carrier;
        this.getTerminals();
      }
    }
  },
  methods: {
    closeDialog: function () {
      this.$emit("close_destination_dialog")
    },
    saveDestination: function () {

    },
    getTerminals: function () {
      if (this.shipmentDestination.data.carrier) {
        let terminalUrl = axios.defaults.baseURL + '/shipment/terminals/' + this.shipmentDestination.data.carrier;
        let terminals = [];
        axios.get(terminalUrl).then((response) => {
          for (let terminal_key in response.data.terminals) {
            if (response.data.terminals[terminal_key].country == this.shipmentDestination.data.destination.country) {
              let terminal = {
                'text': response.data.terminals[terminal_key].name,
                'value': response.data.terminals[terminal_key].id
              };
              terminals.push(terminal)
            }
          }
          this.terminals = terminals;
        });
      }
    },
    changeCourier: function (carrier) {
      this.shipmentDestination.data.carrier = carrier;
      this.getTerminals();
    },
    changeCountry: function (country) {
      this.shipmentDestination.data.destination.country = country;
      let carrier = this.shipmentDestination.data.carrier;
      this.getTerminals(carrier);
    },
    updateDestination: function () {
      axios.get(axios.defaults.baseURL + '/sanctum/csrf-cookie')
          .then(response => {
            axios.post(axios.defaults.baseURL + '/shipment/update',
                this.shipmentDestination)
                .then((response) => {
                      this.$emit("fetch_shipment", this.shipmentDestination.id)
                    }
                )
          });
          this.$emit("close_destination_dialog");

    }
  }
}
</script>

<style scoped>

</style>